import React, { ReactElement } from 'react'
import searchIcon from '../../../assets/img/searchOutlined.svg'

export function SearchInput({
    value,
    handleSearch,
    customStyle,
    placeholder,
    onAction,
    isDisabled,
    customClass,
}: {
    value: string
    placeholder?: string
    handleSearch: any
    customStyle?: any
    customClass?: any
    onAction?: any
    isDisabled?: boolean
}): ReactElement {
    return (
        <div className="w-full 2xl:w-80  md:w-60 relative">
            <input
                placeholder={placeholder || 'Search'}
                value={value}
                style={{ ...customStyle }}
                className={`h-11 pl-3 py-5 text-lg font-Inter rounded-md border w-full focus:outline-none text-[#3A434B] text-[13px] placeholder-[#0D0F11] placeholder:text-[13px] ${customClass}`}
                onChange={(e) => handleSearch(e.target.value)}
                disabled={isDisabled}
            />
            <img
                src={searchIcon}
                alt="search"
                className="absolute top-3 right-4 w-5 cursor-pointer"
                onClick={onAction}
            />
        </div>
    )
}
